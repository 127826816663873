import React from 'react';
import Loadable from 'react-loadable';
import SectionUsage from '../partials/_SectionUsage';
import SectionPlatform from '../partials/_SectionPlatform';
import SectionMicroLabels from '../partials/_SectionMicroLabels';
import SectionMicroPlaceholders from '../partials/_SectionMicroPlaceholders';
import SectionMicroHelpText from '../partials/_SectionMicroHelpText';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import inputPreviewData from '../../../../data/previews/input.yml';
import textareaPreviewData from '../../../../data/previews/textarea.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const Textarea = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Textarea;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Text Area"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Forms" tierThree="Text Area" />
      <V5Notice
        componentName="Text Area"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-text-area--docs"
      />

      <PageNavigation
        links={[
          'Size',
          'State',
          'Usage',
          'Microcopy',
          'Accessibility',
          'Platform',
        ]}
      />

      <Section title="Size">
        <Paragraph>
          An text area can be any of the four available sizes.
        </Paragraph>
        <ComponentPreview
          name="TextareaSize"
          layout="halfWidth"
          previewData={textareaPreviewData.formSize}>
          <Textarea
            label="Additional Feedback"
            placeholder="Let us know what went well or what we could improve."
            helpText="Max. 500 words"
          />
        </ComponentPreview>
        <Paragraph>
          A form’s density (standard, compact) is dictated by the form modifier
          component. Check{' '}
          <Link href="/components/forms/form-modifier/design">
            those guidelines
          </Link>{' '}
          for more detailed recommendations.
        </Paragraph>
      </Section>

      <Section title="State">
        <Paragraph>
          A text area’s state depends on the interaction itself or a submission
          error.
        </Paragraph>
        <ComponentPreview
          name="TextareaState"
          previewData={inputPreviewData.state}>
          <Textarea
            label="Additional Feedback"
            placeholder="Let us know what went well or what we could improve."
            helpText="Max. 500 words"
            rows={8}
          />
        </ComponentPreview>
      </Section>

      <SectionUsage hasLabel />

      <Section title="Microcopy">
        <SectionMicroLabels imgName="textarea-label" />
        <SectionMicroPlaceholders imgName="textarea-placeholder" />
        <SectionMicroHelpText
          imgNameSample="textarea-helptext"
          imgNameError="textarea-errorhelptext"
        />
      </Section>

      <AccessibilityAuditComponentResultsSection componentName="Forms / Textarea" />

      <SectionPlatform />
    </PageWithSubNav>
  );
};

export default IndexPage;
