import React from 'react';
import { PlatformTable, Section } from '../../../../components';

const SectionPlatform = ({}) => {
  return (
    <Section title="Platform">
      <PlatformTable />
    </Section>
  );
};

export default SectionPlatform;
