import React from 'react';
import { DontDo, Paragraph, SectionSubhead } from '../../../../components';

const SectionMicroHelpText = ({ imgNameSample, imgNameError }) => {
  return (
    <div>
      <SectionSubhead>Help Text</SectionSubhead>
      {imgNameSample && (
        <div>
          <Paragraph>
            Help text can provide details not included in the label or
            placeholder, like certain criteria to meet for a valid submission.
          </Paragraph>
          <DontDo
            dontText="use the help text to provide a sample entry."
            doText="explain what restrictions might exist in that field."
            imgFilename={imgNameSample}
          />
        </div>
      )}
      <Paragraph>
        In the case of an error, the help text should be updated explaining why
        an error occurred and how it’s fixed.
      </Paragraph>
      <DontDo
        dontText="allow errors without displaying help text."
        doText="use help text to explain why an error occurred and how it's fixed."
        imgFilename={imgNameError}
      />
    </div>
  );
};

export default SectionMicroHelpText;
