import React from 'react';
import {
  DontDo,
  Link,
  Paragraph,
  SectionSubhead,
} from '../../../../components';

const SectionMicroLabels = ({ imgName }) => {
  return (
    <div>
      <SectionSubhead>Labels</SectionSubhead>
      <Paragraph>
        A label should clearly and concisely describe the field’s purpose with
        no more than a few words.
      </Paragraph>
      <DontDo
        dontText="make the label a complete sentence telling the user what to do."
        doText="name exactly what belongs in that field."
        imgFilename={imgName}
      />

      <Paragraph>
        Check{' '}
        <Link href="http://hudl.com/design/writing/writing-for/teams/product">
          UI label guidelines
        </Link>{' '}
        for more detail on case and punctuation.
      </Paragraph>

      <Paragraph>
        For required fields,{' '}
        <strong>place an asterisk at the end of the label</strong>. There’s no
        need to spell out required and extend the length of that label.
      </Paragraph>
    </div>
  );
};

export default SectionMicroLabels;
