import React from 'react';
import {
  DontDo,
  Link,
  Paragraph,
  SectionSubhead,
} from '../../../../components';

const SectionMicroPlaceholders = ({ imgName }) => {
  return (
    <div>
      <SectionSubhead>Placeholders</SectionSubhead>
      <Paragraph>
        Placeholders are suggested but not always necessary. They shouldn’t
        repeat the label outright or insert the label into a CTA (e.g., “Enter
        your email address”).
      </Paragraph>
      <DontDo
        dontText="repeat the label for the sake of having a placeholder."
        doText="provide more instruction with a sample entry."
        imgFilename={imgName}
      />
      <Paragraph>
        They can provide an example or preferred format of the content we’re
        looking for, like a date or phone number.
      </Paragraph>
      <Paragraph>
        Placeholders should follow{' '}
        <Link href="http://hudl.com/design/writing/writing-for/teams/product">
          non-label text guidelines
        </Link>
        , but don’t put punctuation on the end.
      </Paragraph>
    </div>
  );
};

export default SectionMicroPlaceholders;
